export interface UtilsIndievibeAmplitudeProps {
  amplitudeKey?: string;
}

const amplitudeOptions = {
  includeUtm: true,
  includeReferrer: true,
  unsetParamsReferrerOnNewSession: true,
  includeGclid: true,
};

export function UtilsIndievibeAmplitude(props: UtilsIndievibeAmplitudeProps) {
  if (!props.amplitudeKey) {
    return null;
  }

  return (
    <script
      async
      defer
      dangerouslySetInnerHTML={{
        __html: `
      (function(e,t){var n=e.amplitude||{_q:[],_iq:{}};var r=t.createElement("script")
      ;r.type="text/javascript"
      ;r.integrity="sha384-tzcaaCH5+KXD4sGaDozev6oElQhsVfbJvdi3//c2YvbY02LrNlbpGdt3Wq4rWonS"
      ;r.crossOrigin="anonymous";r.async=true
      ;r.src="https://cdn.amplitude.com/libs/amplitude-8.5.0-min.gz.js"
      ;r.onload=function(){if(!e.amplitude.runQueuedFunctions){
      console.log("[Amplitude] Error: could not load SDK")}}
      ;var i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)
      ;function s(e,t){e.prototype[t]=function(){
      this._q.push([t].concat(Array.prototype.slice.call(arguments,0)));return this}}
      var o=function(){this._q=[];return this}
      ;var a=["add","append","clearAll","prepend","set","setOnce","unset","preInsert","postInsert","remove"]
      ;for(var c=0;c<a.length;c++){s(o,a[c])}n.Identify=o;var u=function(){this._q=[]
      ;return this}
      ;var l=["setProductId","setQuantity","setPrice","setRevenueType","setEventProperties"]
      ;for(var p=0;p<l.length;p++){s(u,l[p])}n.Revenue=u
      ;var d=["init","logEvent","logRevenue","setUserId","setUserProperties","setOptOut","setVersionName","setDomain","setDeviceId","enableTracking","setGlobalUserProperties","identify","clearUserProperties","setGroup","logRevenueV2","regenerateDeviceId","groupIdentify","onInit","logEventWithTimestamp","logEventWithGroups","setSessionId","resetSessionId"]
      ;function v(e){function t(t){e[t]=function(){
      e._q.push([t].concat(Array.prototype.slice.call(arguments,0)))}}
      for(var n=0;n<d.length;n++){t(d[n])}}v(n);n.getInstance=function(e){
      e=(!e||e.length===0?"$default_instance":e).toLowerCase()
      ;if(!Object.prototype.hasOwnProperty.call(n._iq,e)){n._iq[e]={_q:[]};v(n._iq[e])
      }return n._iq[e]};e.amplitude=n})(window,document);
     
    `,
      }}
    />
  );
}

export const IndievibeAmplitudeEventNames = {
  PAGE_VIEWED: 'PAGE_VIEWED',
  CATEGORY_INTERACTION: 'CATEGORY_INTERACTION',
  PRODUCT_CARD_INTERACTION: 'PRODUCT_CARD_INTERACTTION',
  ADDED_TO_CART: 'ADDED_TO_CART',
  ADDED_TO_WISHLIST: 'ADDED_TO_WISHLIST',
  PRODUCT_SHARED: 'PRODUCT_SHARED',
  SELECT_ADDRESS_INITIATED: 'SELECT_ADDRESS_INITIATED',
  PROCEED_TO_PAYMENT: 'PROCEED_TO_PAYMENT',
  PAYMENT_TRANSACTION_INITIATED: 'PAYMENT_TRANSACTION_INITIATED',
  ADDRESS_ADDED: 'ADDRESS_ADDED',
  ADDRESS_EDITED: 'ADDRESS_EDITED',
  ADDRESS_DELETED: 'ADDRESS_DELETED',
  LOGIN_INITIATED: 'LOGIN_INITIATED',
  LOGIN_SUCCESSFUL: 'LOGIN_SUCCESSFUL',
  ERROR_SCREEN: 'ERROR_SCREEN',
  ERROR_SCREEN_BACK_TO_HOME: 'ERROR_SCREEN_BACK_TO_HOME',
  FOOTER_INTERACTION: 'FOOTER_INTERACTION',
  DELETED_FROM_CART: 'DELETED_FROM_CART',
  GO_TO_CART: 'GO_TO_CART',
  SEARCH_SUGGESTION_INTERACTED: 'SEARCH_SUGGESTION_INTERACTED',
  PAYMENT_METHOD_SELECTED: 'PAYMENT_METHOD_SELECTED',
  VIEW_OFFER_DETAILS: 'VIEW_OFFER_DETAILS',
  VIEW_DELIVERY_CHARGE_DETAILS: 'VIEW_DELIVERY_CHARGE_DETAILS',
  VIEW_ALL_AVAILABLE_OFFERS: 'VIEW_ALL_AVAILABLE_OFFERS',
  APPLY_OFFER: 'APPLY_OFFER',
  CHECK_OFFER_VALIDITY: 'CHECK_OFFER_VALIDITY',
  VIEW_FULL_CATALOGUE: 'VIEW_FULL_CATALOGUE',
  CONTACT_SELLER: 'CONTACT_SELLER',
  VIEW_ALL_CLICKED: 'VIEW_ALL_CLICKED',
  NEXT_CLICKED: 'NEXT_CLICKED',
  PREV_CLICKED: 'PREV_CLICKED',
  GO_TO_WISHLIST: 'GO_TO_WISHLIST',
  GO_TO_ACCOUNT: 'GO_TO_ACCOUNT',
  GO_TO_EXPLORE: 'GO_TO_EXPLORE',
  ADDRESS_SELECTED: 'ADDRESS_SELECTED',
  CURATED_CATEGORY_INTERACTED: 'CURATED_CATEGORY_INTERACTED',
  RESET_PASSWORD_INITIATED: 'RESET_PASSWORD_INITIATED',
  SIGNUP_CLICKED: 'SIGNUP_CLICKED',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  ONBOARDING_SUCCESS: 'ONBOARDING_SUCCESS',
  PRODUCT_MEDIA_CLICKED: 'PRODUCT_MEDIA_CLICKED',
  PRODUCT_SAMPLE_ADDED: 'PRODUCT_SAMPLE_ADDED',
  SEARCH_CLICKED: 'SEARCH_CLICKED',
  PAYMENT_INITIATED: 'PAYMENT_INITIATED',
  DELETED_FROM_WISHLIST: 'DELETED_FROM_WISHLIST',
};

export const AmplitudeUserProperties = {
  DEVICE_TYPE: 'deviceType',
};

export type AmplitudeProperties = {
  referrer?: string;
  page_type?: string;
  page_url?: string;
  page_name?: string;
  page_identifier?: string;
  parent_l1_category?: string;
  parent_l0_category?: string;
  category_filter?: string;
  price_filter?: string;
  discount_filter?: string;
  sort_filter?: string;
  page_num?: number;
  source?: string;
  category_level?: number;
  name?: string;
  source_name?: string;
  source_identifier?: string;
  source_l0_category?: string;
  source_l1_category?: string;
  source_l2_category?: string;
  identifier?: string;
  shop_name?: string;
  product_price?: number;
  product_variation?: string;
  shop_id?: string;
  shop_slug?: string;
  l0_category?: string;
  l1_category?: string;
  l2_category?: string;
  view_more_l2_category?: string;
  city?: string;
  pincode?: string;
  order_amount?: number;
  total_order_amount?: number;
  delivery_charges?: number;
  payment_method?: string;
  is_retry?: boolean;
  order_status?: string;
  error_code?: string;
  social_icon?: string;
  order_id?: string;
  quantity?: number;
  search_keyword?: string;
  suggestion_type?: string;
  position?: number;
  count?: number;
  order_type?: string;
  is_shop_platform?: boolean;
  offer_code?: string;
  is_b2b_platform?: boolean;
  type?: string;
  review_type?: 'product' | 'seller';
  itemUsId?: string;
  product_name?: string;
  login_type?: string;
  product_id?: string;
  country?: string;
  store_type?: string;
  product_type?: string;
  is_shipping_charge_added?: boolean;
  media_type?: string;
  payment_type?: string;
};

declare global {
  interface Window {
    amplitude: any;
  }
}

export function sendAmplitudeData(eventName: string, amplitudeProperties: AmplitudeProperties) {
  
  if (typeof window !== 'undefined' && window.amplitude && process.env.NEXT_PUBLIC_AMPLITUDE_KEY) {
    if (!window?.amplitude?.getInstance()?.options?.deviceId) {
      window.amplitude.getInstance().init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY, null, {
        includeUtm: true,
        includeReferrer: true,
        unsetParamsReferrerOnNewSession: true,
        includeGclid: true,
      });
    }

    amplitudeProperties.page_url = window.location.href;
    amplitudeProperties.referrer = document.referrer;
    window.amplitude.getInstance().logEvent(eventName, amplitudeProperties);
  } else {
    console.log(eventName, amplitudeProperties);
  }
}

export function setAmplitudeUserId(userId: string) {
  if (typeof window !== 'undefined' && window.amplitude && process.env.NEXT_PUBLIC_AMPLITUDE_KEY) {
    if (!window?.amplitude?.getInstance()?.options?.deviceId) {
      window.amplitude.getInstance().init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY, null, {
        includeUtm: true,
        includeReferrer: true,
        unsetParamsReferrerOnNewSession: true,
        includeGclid: true,
      });
    }

    window.amplitude.setUserId(userId);
  }
}

export function setUserProperty(key: string, value: string) {
  if (typeof window !== 'undefined' && window.amplitude && process.env.NEXT_PUBLIC_AMPLITUDE_KEY) {
    if (!window?.amplitude?.getInstance()?.options?.deviceId) {
      window.amplitude.getInstance().init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY, null, {
        includeUtm: true,
        includeReferrer: true,
        unsetParamsReferrerOnNewSession: true,
        includeGclid: true,
      });
    }

    const identify = new window.amplitude.Identify().set(key, value);
    window.amplitude.identify(identify);
  }
}